import { PermissionContext } from '@/App';
import applyIcon from '@/assets/img/home/apply.png';
import jiraIcon from '@/assets/img/home/jira.png';
import mapCollectIcon from '@/assets/img/home/mapCollect.png';
import mapCollectVehicleModeIcon from '@/assets/img/home/mapCollectVehicleMode.png';
import messageIcon from '@/assets/img/home/message.png';
import routeAuditIcon from '@/assets/img/home/routeAudit.png';
import routeDeliveryIcon from '@/assets/img/home/routeDelivery.png';
import transferIcon from '@/assets/img/home/transfer.png';
import { ZIcon } from '@/components';
import Header from '@/components/header';
import { getFlowMessages } from '@/service';
import { getQueryVariable, getStorage, removeStorage, request } from '@/utils';
import { Badge, List, Popover, Space } from 'antd-mobile';
import { DownOutline } from 'antd-mobile-icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.less';

export const logout = (redirectCurrent = true) => {
  removeStorage('access_token');
  removeStorage('username');
  removeStorage('organizationId');
  removeStorage('detailInfo');
  removeStorage('userId');
  request.ssoManager.gotoLogoutPage(redirectCurrent);
};

interface IProps {
  fromApp: boolean;
}
const MainView = (props: IProps) => {
  const [messageCount, setMessageCount] = useState<number>(0);
  const navigate = useNavigate();
  const username = decodeURI(getQueryVariable('username'));

  const renderUsername = username || getStorage('username') || 'Zelos';

  const permissions = useContext(PermissionContext);

  const navList = [
    {
      title: 'JIRA提报',
      icon: jiraIcon,
      path: '/problem',
      permissions: [],
    },
    {
      title: '我的申请',
      icon: applyIcon,
      path: '/flow',
      permissions: [],
    },
    {
      title: '数据回传',
      icon: transferIcon,
      path: '/transfer',
      permissions: [],
    },
    {
      title: '采图任务',
      icon: mapCollectIcon,
      path: '/mapCollect',
      permissions: ['CircleMakeQuery'],
    },
    {
      title: '审批消息',
      icon: messageIcon,
      path: '/messageList',
      right: messageCount > 0 && <Badge content={messageCount} />,
      permissions: [],
    },
    {
      title: '采图车模式切换',
      icon: mapCollectVehicleModeIcon,
      path: '/mapCollectVehicleMode',
      permissions: ['MapVehicleModuleConfig'],
    },
    {
      title: '采图路线审核和分配',
      icon: routeAuditIcon,
      path: '/routeAudit',
      permissions: [],
    },
    {
      title: '路线交付管理',
      icon: routeDeliveryIcon,
      path: '/routeDelivery',
      permissions: [],
    },
    {
      title: '提报工单',
      icon: applyIcon,
      path: '/case',
      permissions: [],
    },
  ];

  const addListener = () => {
    document.addEventListener('visibilitychange', listenerHandler);
  };

  const removeListener = () => {
    document.removeEventListener('visibilitychange', listenerHandler);
  };

  const listenerHandler = () => {
    console.log(document.visibilityState, 'document.visibilityState');
    if (document.visibilityState === 'visible') {
      window.location.reload();
    }
  };

  const fetchMessages = () => {
    getFlowMessages().then((res) => {
      if (res?.success) {
        setMessageCount(res?.data?.total);
      }
    });
  };

  useEffect(() => {
    console.log('组件创建');
    addListener();
    fetchMessages();

    return () => {
      console.log('组件销毁');
      removeListener();
    };
  }, []);

  return (
    <div>
      <Header
        backArrow={false}
        right={
          props.fromApp ? (
            <span
              style={{
                fontSize: '15px',
                color: '#212121',
              }}
            >
              {renderUsername}
            </span>
          ) : (
            <Popover.Menu
              actions={[
                {
                  text: '退出登录',
                  icon: <ZIcon type="icon-export" />,
                  key: '1',
                  onClick: logout,
                },
              ]}
              trigger="click"
            >
              <Space align="center">
                <span
                  style={{
                    fontSize: '15px',
                    color: '#212121',
                  }}
                >
                  {renderUsername}
                </span>
                <DownOutline />
              </Space>
            </Popover.Menu>
          )
        }
        style={{
          marginBottom: '10px',
        }}
        title="运营工具"
      />
      {permissions?.length > 0 && (
        <List>
          {navList.map(
            (item) =>
              item?.permissions?.every((p) => permissions?.includes(p)) && (
                <List.Item
                  key={item.path}
                  prefix={
                    <img
                      src={item.icon}
                      alt="icon"
                      style={{
                        height: '18px',
                        width: '18px',
                        margin: 'auto 0',
                        display: 'block',
                      }}
                    />
                  }
                  extra={item.right}
                  onClick={() => navigate(item.path)}
                >
                  {item.title}
                </List.Item>
              ),
          )}
        </List>
      )}
    </div>
  );
};
export default MainView;
